/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { SetAccountI18nConfig, SetLegaleI18nConfig, SetServices } from '@stores/services/services.actions';
import { CountryService } from '@wizbii/models';
import { AccountWebservice } from '@wizbii/webservices';

export class ServicesStateModel {
  services: CountryService[];
  accountI18nConfig: CountryService;
  legaleI18nConfig: CountryService;
}

const ServicesStateToken = new StateToken<ServicesStateModel>('services');

const defaultState: ServicesStateModel = {
  services: undefined,
  accountI18nConfig: undefined,
  legaleI18nConfig: undefined,
};

@State<ServicesStateModel>({
  name: ServicesStateToken,
  defaults: defaultState,
})
@Injectable()
export class ServicesState implements NgxsOnInit {
  static getService(serviceId: string) {
    return createSelector([ServicesStateToken], (services: CountryService[]) =>
      services.find((service) => serviceId.includes(service.id) || service.id.includes(serviceId))
    );
  }

  @Selector([ServicesStateToken])
  static services(state: ServicesStateModel) {
    return state.services;
  }

  @Selector([ServicesStateToken])
  static accountI18nConfig(state: ServicesStateModel) {
    return state.accountI18nConfig;
  }

  @Selector([ServicesStateToken])
  static legaleI18nConfig(state: ServicesStateModel) {
    return state.legaleI18nConfig;
  }

  @Selector([ServicesState.services])
  static servicesDisplayed(services: CountryService[]) {
    return services.filter((service) => !!service.universe);
  }

  constructor(private readonly accountWebService: AccountWebservice) {}

  ngxsOnInit(ctx?: StateContext<any>) {
    this.accountWebService.getServicesByCountry(environment.locale).subscribe({
      next: (country) => {
        ctx.setState({
          services: country.services,
          accountI18nConfig: country.services.find((service) => service.id === 'account'),
          legaleI18nConfig: country.services.find((service) => service.id === 'legals'),
        });
      },
    });
  }

  @Action(SetServices)
  setServices(ctx: StateContext<ServicesStateModel>, { services }: SetServices) {
    ctx.patchState({
      services,
    });
  }

  @Action(SetAccountI18nConfig)
  setAccountI18nConfig(ctx: StateContext<ServicesStateModel>, { accountI18nConfig }: SetAccountI18nConfig) {
    ctx.patchState({
      accountI18nConfig,
    });
  }

  @Action(SetLegaleI18nConfig)
  setLegaleI18nConfig(ctx: StateContext<ServicesStateModel>, { legaleI18nConfig }: SetLegaleI18nConfig) {
    ctx.patchState({
      legaleI18nConfig,
    });
  }
}
