<wiz-header
  *ngIf="showInterservicesHeader"
  class="wiz-header"
  cookieKey="wizbii_tokens"
  [attr.app]="appId"
  [attr.currentLocale]="locale"
  [attr.apidomain]="apiDomain"
  [attr.currentlocale]="locale"
  [attr.authapiurl]="authtApiUrl"
  [attr.accountapiurl]="accountApiUrl"
></wiz-header>

<main #container class="container">
  <app-header></app-header>
  <div class="content">
    <router-outlet></router-outlet>
  </div>

  <wiz-footer
    class="main-footer"
    contactEmailKey="dpo"
    [attr.appid]="appId"
    [attr.apidomain]="apiDomain"
    [attr.localeshandled]="localesHandled"
    [attr.currentlocale]="locale"
    [attr.contactapiurl]="contactApiUrl"
    [attr.accountapiurl]="accountApiUrl"
    (updateLocale)="updatePreferredLocale($event['detail'])"
  >
    <span slot="wzbFooterListInside">
      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.CGU]"
          (click)="trackEvent('Footer', 'Click Conditions d\'utilisation')"
          i18n
          >Conditions d'utilisation</a
        >
      </li>

      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Privacy_policy]"
          (click)="trackEvent('Footer', 'Click Confidentialité')"
          i18n
          >Confidentialité</a
        >
      </li>

      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Cookies]"
          (click)="trackEvent('Footer', 'Click Utilisation des cookies')"
          i18n
          >Utilisation des cookies</a
        >
      </li>

      <li>
        <a [routerLink]="['/', FeaturesRoutingEnum.Legal]" (click)="trackEvent('Footer', 'Click Mentions légales')" i18n
          >Mentions légales</a
        >
      </li>

      <!-- <li><a href="mailto:dpo@wizbii.com" (click)="trackEvent('Footer', 'Click Contact')" i18n>Contact</a></li> -->
    </span>
  </wiz-footer>
</main>

<app-sprite></app-sprite>
