import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule, Routes } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';

const routes: Routes = [
  {
    path: FeaturesRoutingEnum.CGU,
    loadChildren: () => import('./cgu/routes').then((m) => m.CGU_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.CPU,
    loadChildren: () => import('./cpu/routes').then((m) => m.CPU_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.CGV,
    loadChildren: () => import('./cgv/routes').then((m) => m.CGV_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.Privacy_policy,
    loadChildren: () => import('./confidentiality/routes').then((m) => m.CONFIDENTIALITY_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.Cookies,
    loadChildren: () => import('./cookies/routes').then((m) => m.COOKIES_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.Legal,
    loadChildren: () => import('./legal/routes').then((m) => m.LEGAL_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.Updates,
    loadChildren: () => import('./updates/routes').then((m) => m.UPDATES_ROUTES),
  },
  {
    path: FeaturesRoutingEnum.Home,
    loadChildren: () => import('./home/routes').then((m) => m.HOME_ROUTES),
  },
];

@NgModule({
  imports: [
    RouterModule.forChild([
      ...routes,
      {
        path: ':lang',
        children: [...routes],
      },
    ]),
    MatDialogModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FeaturesModule {}
