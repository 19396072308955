import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trackingConfig } from '@config/tracking-config';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { WINDOW } from '@wizbii/angular-utilities';
import { LocaleEnum } from '@wizbii/models';
import { setupTracking, track, trackEvent } from '@wizbii/tracking';
import { AccountWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  showInterservicesHeader = true;
  subscriptions: Subscription[] = [];

  appId = environment.applicationId;
  apiDomain = environment.apiDomain;
  localesHandled = [LocaleEnum.fr_FR, LocaleEnum.en_GB, LocaleEnum.es_ES, LocaleEnum.it_IT];
  locale = environment.locale;
  LocaleEnum = LocaleEnum;
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  track = track;
  trackEvent = trackEvent;

  private readonly cookieName = 'preferred-locale';
  private readonly cookieExpiryName = 'preferred-locale_expiry';

  private readonly destroyed$ = new Subject<void>();

  readonly accountApiUrl: string;
  readonly authtApiUrl: string;
  readonly contactApiUrl: string;

  constructor(
    readonly loadingBarService: LoadingBarService,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    private readonly cookieService: CookieService,
    @Inject(WINDOW) private readonly window: any,
    private readonly router: Router,
    private readonly accountWebService: AccountWebservice
  ) {
    if (!isPlatformBrowser(platformId)) {
      return;
    }

    this.accountApiUrl = isPlatformBrowser(platformId) ? '/_api/account-api' : 'http://account-api';
    this.authtApiUrl = isPlatformBrowser(platformId) ? '/_api/auth-api' : 'http://auth-api';
    this.contactApiUrl = isPlatformBrowser(platformId) ? '/_api/contact-api' : 'http://contact-api';

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(this.window, 'touchstart', { passive: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.hoverEnabled = false;
      });

    setupTracking(trackingConfig(isPlatformBrowser(platformId))).then(() => {
      const { href: url, pathname, search, hash } = window.location;
      const page = `${pathname}${search}${hash}`;
      track('pageview', { url, page });
    });
  }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.document.documentElement.lang === '') {
      this.document.documentElement.lang = environment.lang;
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        const { href: url, pathname, search, hash } = this.window.location;
        const page = `${pathname}${search}${hash}`;
        this.track('pageview', { url, page });
      })
    );
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }

  updatePreferredLocale(locale: string): void {
    if (locale && locale !== this.locale) {
      const cookieDomain = this.getCookieDomain();
      const expiryExists = this.cookieService.check(this.cookieExpiryName);
      const msIn390Days = 1000 * 3600 * 24 * 390;
      const expiry = expiryExists
        ? new Date(this.cookieService.get(this.cookieExpiryName))
        : new Date(Date.now() + msIn390Days);

      if (!expiryExists) {
        this.cookieService.set(
          this.cookieExpiryName,
          expiry.getTime().toString(),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      }

      this.cookieService.set(
        this.cookieName,
        locale,
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );

      if (isPlatformBrowser(this.platformId)) {
        this.accountWebService
          .getServicesByCountry(locale as LocaleEnum)
          .pipe(map((country) => country.services.find((service) => service.id === 'legals')))
          .subscribe((accountI18nConfig) => (this.window.location.href = accountI18nConfig.serviceUri));
      }
    }
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : cookieSubDomain;
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
