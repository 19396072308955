import { APP_BASE_HREF, CommonModule, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@commons/header/header.component';
import { bugsnagConfig } from '@config/bugsnag.config';
import { CoreComponent } from '@core/components/core/core.component';
import { SpriteComponent } from '@core/components/sprite/sprite.component';
import { QuicklinkSharedModule } from '@core/ngx-quicklink.shared.module';
import { AppBrowserViewportScroller } from '@core/scroller/app-browser-viewport-scroller';
import { WizbiiRouterStateSerializer } from '@core/serializer/router.serializer';
import { environment } from '@environment';
import { FeaturesModule } from '@features/features.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ServicesState } from '@stores/services/services.state';
import { bugsnagErrorHandlerFactory } from '@wizbii/angular-bugsnag';
import { DataStorageService, WINDOW_PROVIDERS } from '@wizbii/angular-utilities';
import { ACCOUNT_API_CONFIG, AccountWebservice, CONTACT_API_CONFIG, ContactWebservice } from '@wizbii/webservices';
import { CookieService } from 'ngx-cookie-service';
import { QuicklinkStrategy } from 'ngx-quicklink';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HeaderModule,

    QuicklinkSharedModule,
    RouterModule.forRoot([], {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: QuicklinkStrategy,
    }),
    NgxsModule.forRoot([ServicesState], {
      developmentMode: !environment.isProdBuild,
      selectorOptions: {
        injectContainerState: false,
      },
    }),
    NgxsRouterPluginModule.forRoot(),
    ScrollToModule.forRoot(),
    HttpClientModule,

    MatProgressBarModule,
    LoadingBarModule,
    LoadingBarRouterModule,

    FeaturesModule,

    NgxsReduxDevtoolsPluginModule.forRoot({ name: 'Legals', maxAge: 30 }),
  ],
  providers: [
    DataStorageService,
    CookieService,
    {
      provide: ErrorHandler,
      useFactory: bugsnagErrorHandlerFactory(bugsnagConfig),
    },

    { provide: RouterStateSerializer, useClass: WizbiiRouterStateSerializer },

    { provide: ViewportScroller, useClass: AppBrowserViewportScroller },

    WINDOW_PROVIDERS,
    { provide: APP_BASE_HREF, useValue: '/legal/' },
    {
      provide: CONTACT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/contact-api' : 'http://contact-api',
        locale: environment.locale,
      }),
      deps: [PLATFORM_ID],
    },
    ContactWebservice,
    AccountWebservice,
    {
      provide: ACCOUNT_API_CONFIG,
      useFactory: (platformId: any) => ({
        appId: environment.applicationId,
        baseUrl: isPlatformBrowser(platformId) ? '/_api/account-api' : 'http://account-api',
        locale: environment.locale,
        cookieDomains: environment.domainsByLocale,
        platform: environment.platform,
        defaultCookieDomain: environment.platform === 'local' ? 'localhost' : undefined,
      }),
      deps: [PLATFORM_ID],
    },
  ],
  declarations: [CoreComponent, SpriteComponent],
  bootstrap: [CoreComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
