/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() icon: string;

  @Input() label = '';

  @Input() classes = '';

  // For some reason, Angular always renders components with undefined inputs first.
  // `svg` elements without `width`/`height` attributes are typically given a size of 300x150px.
  // To prevent glitches on initial render, this component's `svg` element is not rendered until
  // the `width` input value becomes available to the template.
  @Input() width: string;

  @Input() height: string;

  @Input() viewBox: string;

  @Input() preserveAspectRatio = 'xMinYMax meet';

  get iconId(): string {
    return `#icon-${this.icon}`;
  }
}
