export enum FeaturesRoutingEnum {
  Home = '',
  CGU = 'cgu',
  CPU = 'cpu',
  CGV = 'cgv',
  Cookies = 'privacy-policy-cookie',
  Legal = 'legal-notice',
  Privacy_policy = 'privacy-policy',
  Updates = 'updates',
}
