import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SvgIconComponent } from '@commons/svg-icon/svg-icon.component';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { WINDOW } from '@wizbii/angular-utilities';
import { LangEnum, LocaleEnum } from '@wizbii/models';
import { trackEvent } from '@wizbii/tracking';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  menuOpen = false;

  isMobile$: Observable<boolean>;
  isTabletLandscape$: Observable<boolean>;
  isTabletPortrait$: Observable<boolean>;
  isSticky$: Observable<boolean>;

  currentRoute: string;
  readonly FeaturesRoutingEnum = FeaturesRoutingEnum;
  readonly LocaleEnum = LocaleEnum;
  readonly LangEnum = LangEnum;

  readonly locale = environment.locale;
  readonly lang = environment.lang;

  trackEvent = trackEvent;

  @ViewChild('nav')
  nav: ElementRef<HTMLElement>;

  @Output() openMobileMenu = new EventEmitter<boolean>();

  get isHome(): boolean {
    return this.currentRoute ? this.currentRoute.split('#')[0].split('?')[0] === '/' : true;
  }

  constructor(
    breakpointObserver: BreakpointObserver,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly router: Router,
    private readonly changeDetectionRef: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: any
  ) {
    this.isMobile$ = breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      debounceTime(300),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTabletLandscape$ = breakpointObserver.observe([Breakpoints.TabletLandscape]).pipe(
      map((result) => result.matches),
      debounceTime(300),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTabletPortrait$ = breakpointObserver.observe([Breakpoints.TabletPortrait]).pipe(
      map((result) => result.matches),
      debounceTime(300),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isSticky$ = fromEvent(this.window, 'scroll', { passive: true }).pipe(
      map(() => this.nav.nativeElement.getBoundingClientRect().top <= 10),
      tap(() => {
        this.changeDetectionRef.detectChanges();
      })
    );

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentRoute = event.url;
      this.changeDetectionRef.detectChanges();
    });
  }

  changeMenuState(open: boolean): void {
    this.openMobileMenu.emit(open);
    this.menuOpen = open;
  }

  clickContactUs(): void {
    this.menuOpen = false;
    const mail = this.document.createElement('a');
    mail.href = 'mailto:contact@drive.wizbii.com?subject=WIZBII Drive - Contact';
    mail.click();
    mail.remove();
    trackEvent('Home Navigation', 'Click Contact');
  }

  navLinkClick(menuOpen: boolean, clickEventName: string): void {
    if (menuOpen !== null) {
      this.menuOpen = menuOpen;
    }
    trackEvent('Home Navigation', clickEventName);
  }
}

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, SvgIconComponent, RouterModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
