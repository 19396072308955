import { environment } from '@environment';
import { createGoogleAnalyticsConfig, TrackingConfig } from '@wizbii/tracking';

export const trackingConfig = (browser: boolean): TrackingConfig => ({
  uuidSetterDomain: browser ? '/_api/analytiks-api' : 'http://analytiks-api',
  informationGroupKeys: ['technical'],
  consentWidget: {
    productId: environment.applicationId,
    version: environment.platform !== 'prod' ? 'staging' : '',
    apiUrl: browser ? '/_api/analytiks-api' : 'http://analytiks-api',
    apiDomain: environment.apiDomain,
    enableTermsModal: true,
    locale: environment.lang,
    defaultValue: false,
    productLabel: 'WIZBII',
    labels: {},
    rejectAll: true,
    consentUrl: browser ? '/_api/consent-api' : 'http://consent-api',
    contentUrl: browser ? '/_api/content-api' : 'http://content-api',
    accountUrl: browser ? '/_api/account-api' : 'http://account-api',
  },
  trackers: {
    analytics_google: createGoogleAnalyticsConfig(environment.analytics.gaTrackingId),
  },
});
