<nav
  #nav
  class="nav"
  [class.nav--open]="menuOpen"
  [class.nav--white-shadowed]="((isMobile$ | async) && !isHome) || (isTabletPortrait$ | async)"
  [class.nav--sticky]="(isSticky$ | async) === true"
>
  <div class="nav__header" [class.nav__header--open]="menuOpen">
    <a class="logo" routerLink="/">
      <img
        src="https://storage.googleapis.com/wizbii/static-assets-v4/logos/SVG/RGB/WIZBII_Le%CC%81gal.svg"
        width="160"
        alt="Logo WIZBII Legal"
      />
    </a>

    <svg-icon
      *ngIf="!menuOpen && ((isMobile$ | async) || (isTabletPortrait$ | async))"
      (click)="menuOpen = true"
      class="burger"
      icon="burger"
      [class.burger--black]="!isHome || menuOpen"
      width="1.25em"
      height="1.035625em"
    ></svg-icon>

    <button *ngIf="menuOpen" (click)="menuOpen = false" class="nav__header__cross">
      <svg-icon icon="cross" width="1.0625em" height="1.0625em"></svg-icon>
    </button>
  </div>

  <div class="nav__list" [class.nav__list--open]="menuOpen">
    <a
      class="nav-item"
      [routerLink]="['/', FeaturesRoutingEnum.CGU]"
      routerLinkActive="nav-item--active"
      (click)="navLinkClick(false, 'Click CGU')"
      i18n
    >
      CGU
    </a>

    <a
      class="nav-item"
      [routerLink]="['/', FeaturesRoutingEnum.CPU]"
      routerLinkActive="nav-item--active"
      (click)="navLinkClick(false, 'Click CPU')"
      i18n
    >
      CPU
    </a>

    <a
      *ngIf="lang === LangEnum.fr"
      class="nav-item"
      [routerLink]="['/', FeaturesRoutingEnum.CGV]"
      routerLinkActive="nav-item--active"
      (click)="navLinkClick(false, 'Click CGV')"
      i18n
    >
      CGV
    </a>

    <a
      class="nav-item"
      routerLinkActive="nav-item--active"
      [routerLink]="['/', FeaturesRoutingEnum.Privacy_policy]"
      (click)="navLinkClick(false, 'Click Confidentialité')"
      i18n
    >
      Confidentialité
    </a>

    <a
      class="nav-item"
      [routerLink]="['/', FeaturesRoutingEnum.Cookies]"
      routerLinkActive="nav-item--active"
      i18n
      (click)="navLinkClick(false, 'Click Cookies')"
    >
      Utilisation des cookies
    </a>

    <a
      class="nav-item"
      [routerLink]="['/', FeaturesRoutingEnum.Legal]"
      routerLinkActive="nav-item--active"
      (click)="navLinkClick(false, 'Click Mention légales')"
      i18n
    >
      Mentions légales
    </a>
  </div>
</nav>
