/* eslint-disable */
import { getBuildEnvVar, getRuntimeEnvVar } from '@wizbii/angular-utilities';
import { LangEnum, LocaleEnum } from '@wizbii/models';
import { buildConfig } from './build-config';

const packageJsonVersionAtBuild = require('../../package.json').version; // eslint-disable-line
// @ts-ignore
const production: boolean = buildConfig === 'production';
const lang: LangEnum = LangEnum[process.env.LANG] || LangEnum.fr;
const locale: LocaleEnum = LocaleEnum[process.env.LOCALE] || LocaleEnum.fr_FR;
const ciCommitSha = getBuildEnvVar('CI_COMMIT_SHA');
const wizbiiDomain = getRuntimeEnvVar('WIZBII_DOMAIN');
const wizbiiDomainIt = getRuntimeEnvVar('WIZBII_DOMAIN_ITALY');
const wizbiiDomainEs = getRuntimeEnvVar('WIZBII_DOMAIN_SPAIN');
const apiDomain = getRuntimeEnvVar('API_DOMAIN');
const apiPlatform = getRuntimeEnvVar('API_PLATFORM');

export const environment = {
  applicationId: 'legals',
  blogId: 'legal-galaxy',
  platform: getRuntimeEnvVar('PLATFORM'),
  domain: getRuntimeEnvVar('WIZBII_DOMAIN'),
  isDeployed: ciCommitSha !== 'local',
  apiDomain,
  cmsBaseUrl:
    apiPlatform === 'prod' ? 'https://legal.strapi.wizbii.team' : 'https://legal.strapi.team.staging.k8s.wizbii.us',
  domainsByLang: {
    [LangEnum.fr]: wizbiiDomain,
    [LangEnum.it]: wizbiiDomainIt,
    [LangEnum.es]: wizbiiDomainEs,
  },
  domainsByLocale: {
    [LocaleEnum.fr_FR]: wizbiiDomain,
    [LocaleEnum.it_IT]: wizbiiDomainIt,
    [LocaleEnum.es_ES]: wizbiiDomainEs,
  },
  isProdBuild: production,
  version: production ? packageJsonVersionAtBuild : getBuildEnvVar('GIT_BRANCH_NAME'),
  lang,
  locale,
  bugsnagId: '1a1ce238c063dc367c19c87f0c8d95ba',
  analytics: {
    waTrackingId: getRuntimeEnvVar('WIZBII_ANALYTICS_UAI'),
    gaTrackingId: getRuntimeEnvVar('GOOGLE_ANALYTICS_UAI'),
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
if (!production) {
  import('zone.js/dist/zone-error' as any); // Included with Angular CLI.
}
